import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertInterface} from "../components/ui/lib-alert/alert.component";


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private isDisplayed: BehaviorSubject<AlertInterface | null> = new BehaviorSubject<AlertInterface | null>(null);
  private displayDuration = 20000;
  public isDisplayed$: Observable<AlertInterface | null> = new Observable();

  constructor() {
    this.isDisplayed$ = this.isDisplayed.asObservable();
  }

  public display(type: "error" | "warning" | "info" | "success", text: string): void {
    this.isDisplayed.next({type, text});
    setInterval(() => {
      this.hide();
    }, this.displayDuration);
  }

  public hide(): void {
    this.isDisplayed.next(null);
  }
}
